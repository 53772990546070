import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'

const axios  = require('axios');
var moment   = require('moment');

moment.locale(); 

export default {
	data: () => ({
    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,

    etiquetas: [
      { idetiquetas: 1, text: 'PENDIENTE DE PAGO'  , color: '#FF0000'},
      { idetiquetas: 2, text: 'CLIENTE POTENCIAL'  , color: '#FFD700'},
      { idetiquetas: 3, text: 'EXAMEN DE UBICACION', color: '#1E90FF'},
      { idetiquetas: 4, text: 'PAGO PARCIAL'       , color: '#FF6D08'},
      { idetiquetas: 5, text: 'INSCRITO'           , color: '#48D91C'},
      { idetiquetas: 6, text: 'VENTANA DE TIEMPO'  , color: '#4A148C'},
      { idetiquetas: 7, text: 'SPAM'               , color: '#000000'},
    ],

    tiempos: [
      { idtiempos: 1, text: 'A tiempo'    , color: 'green'},
      { idtiempos: 2, text: 'Por vencer'  , color: 'yellow'},
      { idtiempos: 3, text: 'Vencido'     , color: 'red'},
    ],
    vendedorasTwilio:[],

  }),

	computed: {
	
		...mapGetters('login', ['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),
    ...mapGetters('twilio',['getConversacionesM','getConversacionesC', 'getConversacionesG','getConversacionesFast','getConversacionesCRM','getConversacionesCRMFast']),
	
	},

	created () {
    this.initialize()
    this.getAccesosCRM()
  },

  methods: {
    // Obtener todos lso chats de un número
    initialize () {
      this.cargar = true
      this.conversaciones = []

      const payload = {
        twilio: this.numeroTwilio,
        idusuario: this.filtroVendedora ? this.filtroVendedora.iderp : this.getdatosUsuario.iderp,
        escuela: this.getEscuela
      }

      return this.$http.post('twilio.chats', payload ).then(response=>{
        console.log('initialize')

        for( const i in response.data ){
          response.data[i]['sinleer'] = 0
        }

        if( this.numeroTwilio == 'whatsapp:+5218187047279' ){
          this.guardarConversacionesM( response.data )
        }

        if( this.numeroTwilio == 'whatsapp:+5215545703336' ){
          this.guardarConversacionesC( response.data )
        }

        if( this.numeroTwilio == 'whatsapp:+5213316971156' ){
          this.guardarConversacionesG( response.data )
        }

        if( this.numeroTwilio == 'whatsapp:+5218123255321' ){
          this.guardarConversacionesFast( response.data )
        }

        if( this.numeroTwilio == 'whatsapp:+5218124384082' ){
          this.guardarConversacionesCRM( response.data )
        }

        if( this.numeroTwilio == 'whatsapp:+5218124387901' ){
          this.guardarConversacionesCRMFast( response.data )
        }

      
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    // Obtener todos lso chats de un número
    getAccesosCRM () {
      this.cargar = true
      return this.$http.get('twilio.acceso.crm/' + this.getdatosUsuario.iderp ).then(response=>{

        this.vendedorasTwilio = response.data
        this.cargar      = false

      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    crearTemplateSonido( ){

      this.sonido = document.createElement("audio");

      this.sonido.src = require("@/assets/tone.mp3");

      this.sonido.setAttribute("preload", "auto");
      this.sonido.setAttribute("controls", "none");
      this.sonido.style.display = "none"; // <-- oculto

      document.body.appendChild(this.sonido);

    },

    async limpiarRecorder( ){

      if( this.recorder ){

        this.recorder.reset()
        this.recorder.clearRecordedData()

        this.recorder.destroy()

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        // Detener la transmisión del flujo de audio del micrófono
        stream.getTracks().forEach(track => track.stop());

        // Destruir la instancia del objeto MediaStream
        stream.getTracks().forEach(track => track.enabled = false);
        stream.getTracks().forEach(track => track.readyState == 'ended');

      }
    },

    validayGuardaMensajes( ){
      // Sacamos el usuario que envío el mensaje
      let conversaciones = []

      if( this.numeroTwilio == 'whatsapp:+5218187047279' ){
        conversaciones = [...this.getConversacionesM];
      }

      if( this.numeroTwilio == 'whatsapp:+5215545703336' ){
        conversaciones = [...this.getConversacionesC];
      }

      if( this.numeroTwilio == 'whatsapp:+5213316971156' ){
        conversaciones = [...this.getConversacionesG];
      }

      if( this.numeroTwilio == 'whatsapp:+5218123255321' ){
        conversaciones = [...this.getConversacionesFast];
      }

      if( this.numeroTwilio == 'whatsapp:+5218124384082'){
        conversaciones = [...this.getConversacionesCRM];
      }

      if( this.numeroTwilio == 'whatsapp:+5218124387901'){
        conversaciones = [...this.getConversacionesCRMFast];
      }

      // Ahora lo buscamos en las conversaciones
      const existeUsuario = conversaciones.find( el => el.usuario == this.chat.usuario )

      // Si existe.... hay que cambiarle el estatus
      if( existeUsuario ){

        for( const i in conversaciones ){

          const { usuario } = conversaciones[i]

          if( usuario == this.chat.usuario ){

            conversaciones[i].mensaje = this.message
            conversaciones[i].sinleer = 0
            conversaciones[i].mio     = 1

          }

        }

      }

      if( this.numeroTwilio == 'whatsapp:+5218187047279' ){
        this.guardarConversacionesM( conversaciones )
      }

      if( this.numeroTwilio == 'whatsapp:+5215545703336' ){
        this.guardarConversacionesC( conversaciones )
      }

      if( this.numeroTwilio == 'whatsapp:+5213316971156' ){
        this.guardarConversacionesG( conversaciones )
      }

      if( this.numeroTwilio == 'whatsapp:+5218123255321' ){
        this.guardarConversacionesFast( conversaciones )
      }

      if( this.numeroTwilio == 'whatsapp:+5218124384082'){
        this.guardarConversacionesCRM( conversaciones )
      }

      if( this.numeroTwilio == 'whatsapp:+5218124387901'){
        this.guardarConversacionesCRMFast( conversaciones )
      }

    },

    cambiarCategoria( etiqueta ){
      // Construimos el chat
      const payload = {
        twilio      : this.numeroTwilio,
        usuario     : this.chat.idusuarios_wpp,
        etiqueta
      }

      // Hacemos la petición de los mensajes
      return this.$http.post('twilio.etiqueta', payload ).then(response=>{

        this.cargar   = false // termina el cargar

        this.initialize( )
        
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })

    },

    // obtener las vendedoras
    getVendedoras() {
      this.cargar = true
      this.vendedoras_plantel  = []
      return this.$http.get('vendedoras.list').then((response) => {
        console.log( response.data  )
        // Llenamos la etapa incial

        const unique = Array.from(
          new Map(response.data.map(item => [item.iderp, item])).values()
        );

        unique.sort((a, b) => a.nombre.localeCompare(b.nombre));

        this.vendedoras_plantel = unique.filter(el=> { return el.escuela == this.escuela})
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },


  },


}